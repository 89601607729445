import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { SquarePen } from "lucide-react";
import { useCurrentUser, useProject, useSetPage } from "../../hooks";
import { assistantClient, Message, Conversation } from "../../clients/sol";
import { ConversationList } from "./ConversationList";
import { DebugModal } from "./Modals/DebugModal";
import { SearchModal } from "./Modals/SearchModal";
import { SettingsModal } from "./Modals/SettingsModal";
import { Chat } from "./Chat";
import { NewConversation } from "./NewConversation";
import { posthog } from "../../clients/posthog";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import FeedbackModal from "./Modals/FeedbackModal";

dayjs.extend(utc);
dayjs.extend(timezone);

const Assistant: React.FC = () => {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [refreshConversationList, setRefreshConversationList] = useState(0);
  const [isSearchModalVisible, setIsSearchModalVisible] = useState(false);
  const [debugModalMessage, setDebugModalMessage] = useState<Message | null>(
    null
  );
  const [openFeedbackModal, setOpenFeedbackModal] = useState<{
    id: string;
    notionId: string;
  } | null>(null);

  const [newConversationMessage, setNewConversationMessage] = useState("");

  const currentUser = useCurrentUser();
  const { projectId = undefined, conversationId = "" } = useParams();

  const conversation = conversationId
    ? conversations.find((c) => c.id === conversationId)
    : undefined;

  const [startDate, setStartDate] = useState<Date>(() =>
    conversation?.start_date
      ? dayjs.utc(conversation.start_date).local().toDate()
      : new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  );

  const [endDate, setEndDate] = useState<Date>(() =>
    conversation?.end_date
      ? dayjs.utc(conversation.end_date).local().toDate()
      : new Date()
  );

  const { project }: any = useProject() || {};

  const setPage = useSetPage();

  useEffect(() => {
    setPage("assistant");
  }, [setPage]);

  const navigate = useNavigate();

  // Fetch conversations list
  useEffect(() => {
    const fetchConversations = async () => {
      if (!currentUser?.id || !projectId) return;

      console.log(`Fetching conversations for user ${currentUser.id}...`);

      try {
        const conversations = await assistantClient.fetchConversationsForUser(
          projectId,
          currentUser.id,
          project?.aiSlackChannelId
        );

        setConversations(conversations);
      } catch (error) {
        console.error("Error fetching conversations:", error);
      }
    };

    fetchConversations();
  }, [currentUser?.id, refreshConversationList]);

  const startNewConversation = async (message: string) => {
    try {
      console.log(`Starting new conversation for user ${currentUser?.id}`);

      const conversationId = await assistantClient.newConversation({
        projectId,
        userId: currentUser?.id,
        slackChannelId: project?.aiSlackChannelId ?? "",
        dateRange: {
          start: dayjs(startDate).utc().format(),
          end: dayjs(endDate).utc().format(),
        },
      });

      console.log(`New conversation started: ${conversationId}`);
      posthog.capture("new_conversation_created", {
        timestamp: new Date().toISOString(),
        userId: currentUser?.id,
        projectId: projectId,
        conversationId: conversationId,
      });

      setNewConversationMessage(message);
      setRefreshConversationList(refreshConversationList + 1);
      navigate(`/p/${projectId}/assistant/c/${conversationId}`);
    } catch (error) {
      console.error("Error starting new conversation:", error);
      return null;
    }
  };

  const clearConversation = () => {
    navigate(`/p/${projectId}/assistant`);
    setRefreshConversationList(refreshConversationList + 1);
  };

  const handleConversationSelect = (conversationId: string) => {
    navigate(`/p/${projectId}/assistant/c/${conversationId}`);
  };

  const updateConversationDateRange = (start: Date, end: Date) => {
    try {
      setStartDate(start);
      setEndDate(end);

      if (conversationId) {
        assistantClient.updateConversationDateRange({
          conversationId,
          start: new Date(start),
          end: new Date(end),
        });
      }
    } catch (error) {
      console.error("Error updating conversation date range:", error);
    }
  };

  useEffect(() => {
    if (conversationId) {
      const conversation = conversations.find((c) => c.id === conversationId);

      if (conversation?.start_date && conversation?.end_date) {
        // Convert UTC dates from conversation to local timezone
        const localStartDate = dayjs
          .utc(conversation.start_date)
          .local()
          .toDate();
        const localEndDate = dayjs.utc(conversation.end_date).local().toDate();

        // Update state with local timezone dates
        setStartDate(localStartDate);
        setEndDate(localEndDate);
      }
    } else {
      setStartDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
      setEndDate(new Date());
    }
  }, [conversationId, conversations]);

  useEffect(() => {
    if (
      conversationId &&
      conversations.length > 0 &&
      !conversations.find((c) => c.id === conversationId)
    ) {
      navigate(`/p/${projectId}/assistant`);
    }
  }, []);

  return (
    <div className="flex h-[80vh]">
      {conversation ? (
        <Chat
          conversation={conversation}
          newConversationMessage={newConversationMessage}
          setNewConversationMessage={setNewConversationMessage}
          setDebugModalMessage={setDebugModalMessage}
          refereshConversationList={() =>
            setRefreshConversationList((prev) => prev + 1)
          }
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          updateConversationDateRange={updateConversationDateRange}
          openFeedbackModal={openFeedbackModal}
          setOpenFeedbackModal={setOpenFeedbackModal}
        />
      ) : (
        <NewConversation
          startNewConversation={startNewConversation}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          updateConversationDateRange={updateConversationDateRange}
        />
      )}

      <div className="w-64 border-l bg-gray-50 flex flex-col gap-2">
        <div className="flex items-center px-4 gap-4">
          <div
            className="topButton flex items-center gap-2"
            onClick={clearConversation}
          >
            New Chat <SquarePen size={15} />
          </div>
        </div>

        <ConversationList
          conversations={conversations}
          currentConversationId={conversationId}
          handleConversationSelect={handleConversationSelect}
          setIsSearchModalVisible={setIsSearchModalVisible}
        />
      </div>

      <SettingsModal
        open={isSearchModalVisible}
        onClose={() => setIsSearchModalVisible(false)}
      />

      <SearchModal
        conversations={conversations}
        open={isSearchModalVisible}
        close={() => setIsSearchModalVisible(false)}
        onSelectConversation={(conversation) => {
          handleConversationSelect(conversation.id);
        }}
      />

      <DebugModal
        message={debugModalMessage}
        onClose={() => setDebugModalMessage(null)}
      />

      <FeedbackModal
        open={openFeedbackModal}
        onClose={() => {
          setOpenFeedbackModal(null);
        }}
      />
    </div>
  );
};

export default Assistant;
