export interface Message {
  text: string;
  sender: "user" | "assistant" | "system";
  timestamp: string;
  prompt?: string;
  retrievalParams?: ActivityRetrievalParams;
}

export interface Conversation {
  id: string;
  name: string;
  project_id: string;
  slack_channel_id?: string;
  created_at: string;
  start_date: string;
  end_date: string;
}

export type SendMessageResponse = {
  prompt: string;
  response: string;
  retrievalParams?: ActivityRetrievalParams;
};

type ActivityRetrievalStrategy = "vector_retrieval" | "date_retrieval" | "scoped_retrieval";

type ActivityRetrievalParams = {
  message: string;
  type: ActivityRetrievalStrategy;
  startDate: string;
  endDate: string;
  rationale?: string;
  options?: {
    collection?: string;
    matchText?: string;
  };
};

export class AssistantClient {
  private baseUrl: string;

  constructor() {
    if (process.env.REACT_APP_ENV === "dev") {
      this.baseUrl = "http://localhost:8080/test";
    } else {
      this.baseUrl = "https://api.aloa.dev/test";
    }
  }

  async newConversation({
    projectId,
    userId,
    slackChannelId,
    dateRange,
  }: {
    projectId?: string;
    userId: string;
    slackChannelId: string;
    dateRange: { start: string; end: string };
  }): Promise<string> {
    try {
      if (!projectId) {
        throw new Error("Project id required to create project");
      }

      const response = await fetch(
        `${this.baseUrl}/assistant/conversations/new`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            projectId,
            userId,
            slackChannelId,
            dateRange,
          }),
        }
      );
      const { conversationId } = await response.json();
      return conversationId;
    } catch (error) {
      console.error("Error creating conversation:", error);
      throw error;
    }
  }

  async sendMessage({
    conversationId,
    message,
    demo,
  }: {
    conversationId: string;
    message: string;
    demo?: boolean;
  }): Promise<SendMessageResponse> {
    const url = demo
      ? `${this.baseUrl}/assistant/conversations/${conversationId}/mockCompletion`
      : `${this.baseUrl}/assistant/conversations/${conversationId}/completion`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        message,
        stream: false,
      }),
    });

    return response.json();
  }

  async sendMessageWithStream({
    conversationId,
    message,
    demo,
    connectionHandler,
    progressHandler,
    completionHandler,
  }: {
    conversationId: string;
    message: string;
    demo?: boolean;
    connectionHandler?: (controller: AbortController) => void;
    progressHandler?: (partial: string) => void;
    completionHandler?: ({
      prompt,
      response,
      retrievalParams,
    }: SendMessageResponse) => void;
  }): Promise<SendMessageResponse> {
    const url = demo
      ? `${this.baseUrl}/assistant/conversations/${conversationId}/mockCompletion`
      : `${this.baseUrl}/assistant/conversations/${conversationId}/completion`;

    const abortController = new AbortController();
    let chunks: string[] = [];

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        signal: abortController.signal,
        body: JSON.stringify({
          message,
          stream: true,
        }),
      });

      if (!response.ok || !response.body) {
        throw new Error("Failed to send message");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");

      return await new Promise(async (resolve, reject) => {
        try {
          while (true) {
            const { done, value } = await reader.read();
            if (done) {
              break;
            }

            let chunk = decoder.decode(value, { stream: true });
            const lines = chunk.trim().split("\n");

            lines.forEach((line) => {
              if (line.startsWith("data: ")) {
                line = line.replace("data: ", "").trim();
                const event: { type: string; data: string } = JSON.parse(line);

                switch (event.type) {
                  case "connected":
                    if (connectionHandler) {
                      connectionHandler(abortController);
                    }
                    break;
                  case "progress":
                    if (progressHandler) {
                      progressHandler(event.data);
                      chunks.push(event.data);
                    }
                    break;
                  case "completed":
                    const { prompt, response, retrievalParams } =
                      event.data as any;

                    if (completionHandler) {
                      completionHandler({ prompt, response, retrievalParams });
                    }

                    resolve({
                      prompt,
                      response,
                      retrievalParams,
                    });
                    break;
                }
              }
            });
          }
        } catch (error) {
          reject(error);
        }
      });
    } catch (error) {
      console.error("Got error handling message stream:", error);
      return {
        prompt: "",
        response: chunks.join(""),
      };
    }
  }

  async fetchConversationsForUser(
    projectId: string,
    userId: string,
    slackChannelId?: string
  ): Promise<Conversation[]> {
    try {
      const response = await fetch(
        `${this.baseUrl
        }/assistant/conversations?userId=${userId}&projectId=${projectId}${slackChannelId ? `&slackId=${slackChannelId}` : ""
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const conversations = await response.json();

      return conversations.sort((a, b) =>
        a.created_at > b.created_at ? -1 : 1
      );
    } catch (error) {
      console.error("Error fetching conversations:", error);
      return [];
    }
  }

  async fetchConversationMessages(conversationId: string): Promise<Message[]> {
    const response = await fetch(
      `${this.baseUrl}/assistant/conversations/${conversationId}/messages`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();
    return data.messages;
  }

  async renameConversation(conversationId: string, name: string) {
    const response = await fetch(
      `${this.baseUrl}/assistant/conversations/${conversationId}/rename`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
        }),
      }
    );

    return response.json();
  }

  async deleteConversation(conversationId: string) {
    const response = await fetch(
      `${this.baseUrl}/assistant/conversations/${conversationId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.json();
  }

  async autonameConversation({
    conversationId,
    message,
    response,
  }): Promise<void> {
    await fetch(
      `${this.baseUrl}/assistant/conversations/${conversationId}/autoname`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userMessage: message,
          aiResponse: response,
        }),
      }
    );
  }

  async updateConversationDateRange({
    conversationId,
    start,
    end,
  }: {
    conversationId: string;
    start: Date;
    end: Date;
  }) {
    await fetch(
      `${this.baseUrl}/assistant/conversations/${conversationId}/dateRange`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          start: start.toISOString(),
          end: end.toISOString(),
        }),
      }
    );
  }

  async sendFeedback(feedback: any) {
    const response = await fetch(
      `${this.baseUrl}/assistant/conversations/feedback`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ feedback })
      }
    );
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const data = await response.json();
    return data;
  }
}

export const assistantClient = new AssistantClient();
