import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Markdown from "react-markdown";
import { useCurrentUser, useProject } from "../../hooks";
import { Check, Copy, ScrollText, ThumbsDown, ThumbsUp } from "lucide-react";
import type { MenuProps } from "antd";
import {
  assistantClient,
  Message,
  SendMessageResponse,
} from "../../clients/sol";
import { TextBox } from "./TextBox";
import { ConversationHeader } from "./ConversationHeader";
import { demoCommands, handleDemoCommand } from "./demo";
import { posthog } from "../../clients/posthog";

// Disable this if we want streaming disabled
let streamEnabled = true;

export function Chat({
  conversation,
  newConversationMessage,
  setNewConversationMessage,
  setDebugModalMessage,
  refereshConversationList,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  updateConversationDateRange,
  openFeedbackModal,
  setOpenFeedbackModal,
}: {
  conversation;
  newConversationMessage: string;
  setNewConversationMessage: (message: string) => void;
  setDebugModalMessage: (message: Message | null) => void;
  refereshConversationList: () => void;
  startDate?: Date;
  setStartDate: (date) => void;
  endDate?: Date;
  setEndDate: (date) => void;
  updateConversationDateRange?: (start: Date, end: Date) => void;
  openFeedbackModal: { id: string; notionId: string } | null;
  setOpenFeedbackModal: (open: { id: string; notionId: string } | null) => void;
}) {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dotCount, setDotCount] = useState(1);
  const [aiLoading, setAiLoading] = useState(false);
  const [newConversationName, setNewConversationName] = useState("");
  const [inProgressMessageChunks, setInProgressMessageChunks] = useState<
    string[]
  >([]);
  const [messageCopied, setMessageCopied] = useState(false);
  const [feedbackStates, setFeedbackStates] = useState<{
    [key: number]: "positive" | "negative" | null;
  }>({});

  const messageBoxRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const loadingController = useRef<AbortController | undefined>(undefined);

  const { projectId = undefined, conversationId = "" } = useParams();
  const { project }: any = useProject() || {};

  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const conversationMenuItems: MenuProps["items"] = [
    {
      key: "rename",
      label: "Rename",
      onClick: () => {
        inputRef.current?.focus();
      },
    },
    {
      key: "delete",
      label: "Delete",
      danger: true,
      onClick: async () => {
        // Delete conversation and refresh list
        await assistantClient.deleteConversation(conversationId);
        refereshConversationList();
        navigate("/p/" + projectId + "/assistant");
      },
    },
  ];

  const updateConversationName = async () => {
    try {
      await assistantClient.renameConversation(
        conversationId,
        newConversationName
      );
      console.log("Conversation name updated:", newConversationName);
    } catch (error) {
      console.error("Error updating conversation name:", error);
    }
  };

  useEffect(() => {
    if (newConversationMessage) {
      handleSubmitMessage(newConversationMessage);
      setNewConversationMessage("");
    }
  }, [newConversationMessage]);

  useEffect(() => {
    if (aiLoading) {
      const interval = setInterval(() => {
        setDotCount((prev) => (prev % 3) + 1);
      }, 500);
      return () => clearInterval(interval);
    }
  }, [aiLoading]);

  // Automate scrolling of the message box when new messages or in-progress message chunks are added
  useEffect(() => {
    if (messageBoxRef.current) {
      messageBoxRef.current.scrollTo({
        top: messageBoxRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages, inProgressMessageChunks]);

  // Load conversation messages when selecting a conversation
  useEffect(() => {
    if (!newConversationMessage) {
      fetchConversationMessages(conversationId);
    }
  }, [conversationId]);

  // Fetch conversation messages
  const fetchConversationMessages = async (conversationId: string) => {
    try {
      const messages = await assistantClient.fetchConversationMessages(
        conversationId
      );
      setMessages(messages);
    } catch (error) {
      console.error("Error fetching conversation messages:", error);
      setMessages([]);
    }
  };

  const sendMessage = async (
    conversationId: string,
    message: string
  ): Promise<SendMessageResponse | null> => {
    setAiLoading(true);

    try {
      let completion: SendMessageResponse | null;

      if (streamEnabled) {
        const sendMessageResponse = await assistantClient.sendMessageWithStream(
          {
            conversationId,
            message,
            connectionHandler: (controller) => {
              loadingController.current = controller;
              setInProgressMessageChunks([]);
            },
            progressHandler: (partial) => {
              setInProgressMessageChunks((prev) => [...prev, partial]);
            },
            completionHandler: () => {
              setAiLoading(false);
            },
          }
        );

        completion = sendMessageResponse;
      } else {
        const sendMessageResponse = await assistantClient.sendMessage({
          conversationId,
          message,
        });
        completion = sendMessageResponse;
      }

      if (messages.length === 0) {
        await assistantClient.autonameConversation({
          conversationId,
          message,
          response: completion?.response,
        });
        refereshConversationList();
      }

      return completion;
    } catch (error) {
      console.error("Error sending message:", error);
      return null;
    } finally {
      setInProgressMessageChunks([]);
      setAiLoading(false);
    }
  };

  const sendDemoMessage = async (
    conversationId: string,
    message: string
  ): Promise<SendMessageResponse | null> => {
    setAiLoading(true);

    try {
      const demoCommand = handleDemoCommand(message);
      if (!demoCommand) {
        throw new Error("Invalid demo command");
      }

      if (streamEnabled) {
        const completion = await assistantClient.sendMessageWithStream({
          conversationId,
          message,
          demo: true,
          connectionHandler: (controller) => {
            loadingController.current = controller;
            setInProgressMessageChunks([]);
          },
          progressHandler: (partial) => {
            setInProgressMessageChunks((prev) => [...prev, partial]);
          },
          completionHandler: () => {
            setInProgressMessageChunks([]);
            setAiLoading(false);
          },
        });

        return completion;
      } else {
        const completion = await assistantClient.sendMessage({
          conversationId,
          message,
          demo: true,
        });
        return completion;
      }
    } catch (error) {
      console.error("Error sending demo message:", error);
      return null;
    } finally {
      setAiLoading(false);
    }
  };

  const handleSubmitMessage = async (message: string) => {
    setIsLoading(true);
    setDotCount(1);

    try {
      const isDemoCommand = message.startsWith("!demo_");
      const newMessage: Message = {
        text: isDemoCommand ? demoCommands[message].userMessage : message,
        sender: "user",
        timestamp: new Date().toLocaleTimeString(),
      };
      setMessages((prev) => [...prev, newMessage]);

      try {
        const response = isDemoCommand
          ? await sendDemoMessage(conversationId, message)
          : await sendMessage(conversationId, message);

        if (response) {
          const aiMessage: Message = {
            text: response?.response,
            prompt: response?.prompt,
            sender: "assistant",
            timestamp: new Date().toLocaleTimeString(),
            retrievalParams: response?.retrievalParams,
          };
          setMessages((prev) => [...prev, aiMessage]);
        }
      } catch (error) {
        const errorMessage: Message = {
          text: "Sorry, I encountered an error processing your message.",
          sender: "assistant",
          timestamp: new Date().toLocaleTimeString(),
        };
        setMessages((prev) => [...prev, errorMessage]);
      }
    } catch (error) {
      console.error("Error in conversation:", error);
      const errorMessage: Message = {
        text: "Sorry, something went wrong. Please try again.",
        sender: "assistant",
        timestamp: new Date().toLocaleTimeString(),
      };
      setMessages((prev) => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFeedback = async (
    messageIndex: number,
    isPositive: boolean,
    message: Message
  ) => {
    try {
      const feedback = {
        user: {
          id: currentUser?.id,
          name: currentUser?.name,
          avatar: currentUser?.image_thumbnail,
        },
        project: {
          id: projectId,
          name: null,
        },
        conversation: {
          id: conversationId,
          name: null,
        },
        query: message.prompt,
        retrieval_params: message.retrievalParams,
        response: message.text,
        feedback: isPositive ? "positive" : "negative",
      };

      // Prevent multiple feedback submissions for the same message
      if (feedbackStates[messageIndex]) {
        return;
      }
      // Update the local feedback state
      setFeedbackStates((prev) => ({
        ...prev,
        [messageIndex]: isPositive ? "positive" : "negative",
      }));
      // Send feedback to posthog
      // Get the user's message that preceded this assistant message
      const userMessage =
        messageIndex > 0 ? messages[messageIndex - 1].text : null;

      const { id, notionId } = await assistantClient.sendFeedback(feedback);

      posthog.capture("conversation_feedback", {
        timestamp: new Date().toISOString(),
        userId: currentUser.id,
        projectId: projectId,
        feedbackType: isPositive ? "positive" : "negative",
        conversationId: conversationId,
        messageIndex: messageIndex,
        assistantMessageContent: messages[messageIndex].text,
        userMessageContent: userMessage, // The user's message that prompted this response
      });

      
      setOpenFeedbackModal({
        id: id,
        notionId: notionId,
      });
    } catch (error) {
      console.error("Error sending feedback:", error);
    }
  };

  const renderMessages = (
    messages: any,
    inProgressMessageChunks: string[],
    formatMessageWithLinks: (text: string) => string
  ) => {
    const messagesToRender =
      inProgressMessageChunks.length > 0
        ? [...messages, processMessageChunks(inProgressMessageChunks)]
        : messages;

    return messagesToRender.map((message, index) => {
      const isAssistant = message.sender === "assistant";
      const feedback = feedbackStates[index];

      return (
        <div
          key={index}
          className={`flex flex-col ${
            message.sender === "user" ? "items-end" : "items-start"
          } group gap-1`}
        >
          <div
            className={`flex flex-row ${
              isAssistant ? "w-full" : "max-w-[70%]"
            }`}
          >
            {isAssistant && (
              <img
                src="https://media.licdn.com/dms/image/v2/C4E0BAQFt5kyr5Wlx0w/company-logo_200_200/company-logo_200_200/0/1631567820609/aloalabs_logo?e=1743033600&v=beta&t=3ZwUJSeMM29Trz0TGPg9aQBqFkQlJrggxf_0_lhtbYU"
                alt="Assistant"
                className="size-6 rounded-full mt-3"
              />
            )}
            <div className="flex flex-col gap-1">
              <div
                className={`${isAssistant ? "px-3" : "p-3"} rounded-lg  ${
                  message.sender === "user"
                    ? "bg-neutral-500/10 text-neutral-600"
                    : "text-gray-800"
                }`}
              >
                <div className="prose prose-sm max-w-none">
                  <div className="[&_code]:break-all [&_code]:whitespace-pre-wrap [&_pre]:whitespace-pre-wrap [&_pre]:break-all overflow-x-auto">
                    <Markdown
                      components={{
                        img: ({ node, ...props }) => (
                          <img
                            {...props}
                            style={{
                              verticalAlign: "middle",
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        ),
                        a: ({ node, ...props }) => (
                          <a
                            {...props}
                            target="_blank"
                            rel="noopener noreferrer"
                          />
                        ),
                      }}
                    >
                      {formatMessageWithLinks(message.text)}
                    </Markdown>
                  </div>
                </div>
              </div>
              {isAssistant && (
                <div
                  className={`px-3 mt-1 flex items-center gap-4 text-neutral-400 opacity-0 transition-opacity group-hover:opacity-100`}
                >
                  {!messageCopied ? (
                    <Copy
                      onClick={() => {
                        navigator.clipboard.writeText(message.text);
                        setMessageCopied(true);
                        setTimeout(() => setMessageCopied(false), 3000);
                      }}
                      strokeWidth={2.3}
                      className="size-3 hover:text-neutral-700 cursor-pointer"
                    />
                  ) : (
                    <Check
                      strokeWidth={2.3}
                      className="size-3 hover:text-neutral-700 cursor-pointer"
                    />
                  )}
                  <ThumbsUp
                    onClick={() => handleFeedback(index, true, message)}
                    strokeWidth={2.3}
                    className={`size-3 cursor-pointer ${
                      feedback === "positive"
                        ? "text-green-500 hover:text-green-600"
                        : "hover:text-neutral-700"
                    } ${
                      feedback === "negative"
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                  />
                  <ThumbsDown
                    onClick={() => handleFeedback(index, false, message)}
                    strokeWidth={2.3}
                    className={`size-3 cursor-pointer ${
                      feedback === "negative"
                        ? "text-red-500 hover:text-red-600"
                        : "hover:text-neutral-700"
                    } ${
                      feedback === "positive"
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                  />
                  <ScrollText
                    onClick={() => {
                      setDebugModalMessage(message);
                    }}
                    strokeWidth={2.3}
                    className="size-3 hover:text-neutral-700 cursor-pointer"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="flex-1 flex flex-col bg-white border-neutral-200 drop-shadow-md relative rounded-xl">
      {conversationId && (
        <div
          className="w-full h-14 flex items-center py-2 px-4"
          style={{ borderBottom: "1px solid #e5e7eb" }}
        >
          <ConversationHeader
            currentConversationId={conversationId}
            conversation={conversation}
            newConversationName={newConversationName}
            setNewConversationName={setNewConversationName}
            updateConversationName={updateConversationName}
            refereshConversationList={refereshConversationList}
            inputRef={inputRef}
            conversationMenuItems={conversationMenuItems}
          />
        </div>
      )}

      <div
        ref={messageBoxRef}
        className="flex-1 overflow-y-auto pt-4 px-5 relative"
      >
        <div className="flex flex-col gap-2 pb-10">
          {renderMessages(
            messages,
            inProgressMessageChunks,
            formatMessageWithLinks
          )}
        </div>
      </div>

      {aiLoading && (
        <div className="px-4 pb-2 z-20">
          <p className="text-sm italic text-gray-500">
            Assistant is thinking{".".repeat(dotCount)}
          </p>
        </div>
      )}

      <TextBox
        isLoading={isLoading}
        onSubmit={handleSubmitMessage}
        stopGeneration={() => {
          if (loadingController.current) {
            loadingController.current.abort();
            setInProgressMessageChunks([]);
          }
        }}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        updateConversationDateRange={updateConversationDateRange}
      />
    </div>
  );
}

const formatMessageWithLinks = (text: string) => {
  if (!text) return text;

  text = cleanupMarkdown(text);

  const linkRegexes = {
    slack: /\[source:slack\]\((https:\/\/aloa\.slack\.com\/archives\/[^)]+)\)/g,
    manage: /\[source:manage\]\((https:\/\/[^)]+)\)/g,
  };

  let formattedText = text;

  // Replace Slack links
  formattedText = formattedText.replace(linkRegexes.slack, (_, url) => {
    return `[![Slack](https://cdn.icon-icons.com/icons2/2699/PNG/32/slack_tile_logo_icon_168820.png)](${url})`;
  });

  // Replace Manage links
  formattedText = formattedText.replace(linkRegexes.manage, (_, url) => {
    return `[![Manage](https://media.licdn.com/dms/image/v2/C4E0BAQFt5kyr5Wlx0w/company-logo_200_200/company-logo_200_200/0/1631567820609/aloalabs_logo?e=1743033600&v=beta&t=3ZwUJSeMM29Trz0TGPg9aQBqFkQlJrggxf_0_lhtbYU)](${url})`;
  });

  return formattedText;
};

const processMessageChunks = (chunks: string[]): Message => {
  const text = cleanupMarkdown(chunks.join(""));
  return {
    text,
    sender: "assistant",
    timestamp: new Date().toLocaleTimeString(),
  };
};

const cleanupMarkdown = (text: string) => {
  return text.replace(/\*\*[ ]+(.+?)[ ]+\*\*/gm, "**$1**");
};
