import React from "react";
import { CalendarFold } from "lucide-react";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import { DatePicker } from "antd";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import { useParams } from "react-router-dom";

dayjs.extend(weekday);
dayjs.extend(localeData);

const { RangePicker } = DatePicker;

interface ChatCalendarProps {
  startDate?: Date;
  setStartDate?: (date: Date) => void;
  endDate?: Date;
  setEndDate?: (date: Date) => void;
  updateConversationDateRange?: (start: Date, end: Date) => void;
}

export const ChatCalendar: React.FC<ChatCalendarProps> = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  updateConversationDateRange,
}) => {
  const handleChange = (values: any) => {
    if (values && values.length === 2) {
      const [start, end] = values;
      setStartDate?.(start.toDate());
      setEndDate?.(end.toDate());
      updateConversationDateRange?.(start.toDate(), end.toDate());
    }
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <RangePicker
        onChange={handleChange}
        // @ts-ignore
        value={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : null}
        suffixIcon={<CalendarFold className="size-4 text-neutral-600" />}
        separator={<span className="text-neutral-400">to</span>}
        format="MMM D, YYYY"
        allowClear={false}
      />
    </div>
  );
};
